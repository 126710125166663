



import { Component, Vue } from 'vue-property-decorator';
import { WPPost, WPCategories } from '../../../types/typescript-axios/api';
import VueRouter, {Route} from 'vue-router';
import axios, {AxiosResponse, AxiosError} from 'axios';
import Error from '../../components/contents/Error.vue';
import CategoryTitle from '../../components/contents/categoryTitle/CategoryTitle.vue';
import Breadcrumb from '@/components/contents/breadcrumb/Breadcrumb.vue';
import store from '@/store';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
]);

let maxLength: number = 0;

async function getContentsData(url: string, params: any, headers: any) {
  const res = await new Promise((resolve, reject) => {
    axios.get<AxiosResponse>(url, {
      params,
      headers,
    })
    .then( (response) => {
      resolve(response);
    })
    .catch( (e: AxiosError) => {
      reject(e);
    });
  });
  return res;
}

async function getDatas(to: Route) {
  let url: string = '/wp-json/wp/v2/voices';
  const catUrl: string = '/wp-json/wp/v2/voices_cat';
  maxLength = 0;
  const categoryRes = await axios.get<AxiosResponse>(catUrl);
  const categoriesRes: any = categoryRes.data;
  const $to: any = to;
  let params: any = {};
  let headers: any = {};
  let categoryId = -1;

  const aryCheck = categoriesRes.filter((value: any) => {
    if (value.slug === $to.query.category && value.count > 0 && value.parent === 0) {
      return true;
    }
  });

  const categories = categoriesRes.filter((value: any) => {
    if (value.count > 0 && value.parent === 0) {
      return true;
    }
  });

  if (categoriesRes.length > 0) {
    for (const data of categoriesRes) {
      maxLength = maxLength + data.count;
    }
  } else {
    maxLength = 100;
  }
  params.per_page = maxLength;

  let queryName: string = '';

  if (typeof $to.query.category !== 'undefined') {
    if (aryCheck.length <= 0) {
      return {
        status: 404,
      };
    } else {
      categoryId = aryCheck[0].id;
      params.voices_cat = categoryId;
      queryName = $to.query.category;
    }
  }

  if ($to.query.preview) {
    url = `${url}/${$to.query.id}/revisions`;
    params = {
      status: 'auto-draft',
    };
    headers = { 'X-WP-Nonce': window.WP_API_Settings.nonce };
  }

  try {
    const resContentsData: any = await getContentsData(url, params, headers);
    const status = resContentsData.status;
    const contents = resContentsData.data;
    return {
      status,
      categoryId,
      contents,
      categories,
      queryName,
    };
  } catch (e) {
    return {
      status: e.response.status,
    };
  }


}

@Component({
  components: {
    Breadcrumb,
    Error,
    CategoryTitle,
  },
})
export default class AboutVoice extends Vue {
  private title: any = '';
  private queryName: string = '';
  private categoryId: number = -1;
  private contents: WPPost[] = [];
  private categories: WPCategories[] = [];
  private status = 200;

  private breadcrumb: BreadcrumbArr = [
    {
      title: 'HOME',
      link: '/',
    },
    {
      title: '受講者様の声',
      link: '',
    },
  ];

  get tabBtns() {
    const categoriesArr = this.categories;
    categoriesArr.unshift({
      name: 'All',
      slug: '',
    });
    return categoriesArr;
  }
  public async beforeRouteEnter(to: Route, from: Route, next: any) {
    const data: any = await getDatas(to);
    if (data.status === 200) {
      let title: string = '';
      for (const item of data.categories) {
        if (item.slug) {
          if (item.slug === data.queryName) {
            title = item.name + ' ';
          }
        }
      }
      store.commit('window/setTitle', title + '受講者様の声 ' + process.env.VUE_APP_TITLE_SUFFIX);
      next((vm: {
        title: any,
        contents: WPPost,
        categories: WPCategories,
        queryName: string,
        categoryId: number,
        status: number,
      }) => {
        vm.status = data.status;
        vm.contents = data.contents;
        vm.categories = data.categories;
        vm.queryName = data.queryName;
        vm.categoryId = data.categoryId;
      });
    } else {
      store.commit('window/setTitle', data.status + ' Error 受講者様の声 ' + process.env.VUE_APP_TITLE_SUFFIX);
      next((vm: {status: number}) => {
        vm.status = data.status;
      });
    }
  }
  public async beforeRouteUpdate(to: Route, from: Route, next: any) {
    const data: any = await getDatas(to);
    this.status = data.status;
    if (data.status === 200) {
      let title: string = '';
      for (const item of data.categories) {
        if (item.slug) {
          if (item.slug === data.queryName) {
            title = item.name + ' ';
          }
        }
      }
      store.commit('window/setTitle', title + '受講者様の声 ' + process.env.VUE_APP_TITLE_SUFFIX);
      this.contents = data.contents;
      this.categories = data.categories;
      this.queryName = data.queryName;
      this.categoryId = data.categoryId;
    } else {
      store.commit('window/setTitle', data.status + ' Error 受講者様の声 ' + process.env.VUE_APP_TITLE_SUFFIX);
    }
    next();
  }
}
